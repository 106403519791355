.auth-left {
    padding: 3em;
}

.swiper-div {
    height: 100%;
}

.swiper {
    height: 80%;
    width: 70%;
    margin-top: 10%;
}

.swiper-slide img {
    display: block;
}

.slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

.swiper-pagination-bullet {
    background-color: #cdcdcd;
    width: 16px;
    height: 16px;
    margin-bottom: 2rem;
    opacity: 1;
}

.swiper-pagination-bullet-active {
    background: #ff516b;
    /* Old browsers */
    background: -moz-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff516b', endColorstr='#826cfd', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}