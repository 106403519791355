@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #fafafa;
  color: #888;
  font-size: 13px;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

.App {
  width: "100vw";
  overflow: hidden;
}

body::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.customScrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F566;
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.m-auto {
  margin: auto;
}

.bg-theme {
  background: #ff516b;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ff516b 0%, #826cfd 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ff516b 0%, #826cfd 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff516b', endColorstr='#826cfd', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-success {
  border-color: transparent !important;
  background: #4eda92;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #4eda92 1%, #56e0cb 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #4eda92 1%, #56e0cb 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #4eda92 1%, #56e0cb 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4eda92', endColorstr='#56e0cb', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-warning {
  border-color: transparent !important;
  background: #ffc107;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ffc107 1%, #fffc07 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ffc107 1%, #fffc07 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc107', endColorstr='#fffc07', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-danger {
  border-color: transparent !important;
  background: #ff253a;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ff253a 0%, #ff8453 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff253a', endColorstr='#ff8453', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-secondary {
  border-color: transparent !important;
  background: #9ba8aa;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #9ba8aa 0%, #92b4d2 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #9ba8aa 0%, #92b4d2 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9ba8aa', endColorstr='#92b4d2', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-info {
  border-color: transparent !important;
  background: #17a2b8;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #17a2b8 1%, #30def9 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #17a2b8 1%, #30def9 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#17a2b8', endColorstr='#30def9', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

.bg-dark {
  border-color: transparent !important;
  background: #171b20;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #171b20 1%, #343a40 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #171b20 1%, #343a40 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #171b20 1%, #343a40 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#171b20', endColorstr='#343a40', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}

#wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#wrapper #content-wrapper {
  overflow-x: hidden;
  width: 100%;
}

#wrapper #content-wrapper .container-fluid {
  padding: 30px 30px 30px 255px;
}

.scroll-to-top {
  background: rgba(52, 58, 64, 0.5) none repeat scroll 0 0;
  border-radius: 70px !important;
  bottom: 86px;
  color: #fff;
  display: none;
  height: 50px;
  line-height: 46px;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
}

.bg-white {
  background-color: #fff;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
  color: white;
}

.scroll-to-top:hover {
  background: #343a40;
}

.scroll-to-top i {
  font-weight: 800;
}

.search-btn {
  background-color: transparent;
}

.hover-opacity {
  opacity: 0.5;
}

.hover-opacity:hover {
  opacity: 1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem;
}

.form-label-group {
  position: relative;
}

.form-label-group>input,
.form-label-group>label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
}

.form-label-group>label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown)~label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}

footer.sticky-footer {
  background-color: #e9ecef;
  padding: 15px;
}

a {
  color: #ff516b;
  text-decoration: none !important;
}

a:hover,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333;
}

.text-dark {
  color: #171b20 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-secondary {
  color: #9ba8aa !important;
}

.text-danger {
  color: #ff253a !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-success {
  color: #4eda92 !important;
}

.text-primary {
  color: #ff516b !important;
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
}